<template>
  <header>
    <nav class="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-transparent mx-auto max-w-screen-xl">
      <div class="flex justify-between items-center mx-auto w-full space-x-4">
        <NuxtLinkLocale to="/" class="flex items-center space-x-3 rtl:space-x-reverse">
          <img src="/img/logo.svg" class="h-18 sm:h-16 md:h-14" />
        </NuxtLinkLocale>
        <div class="flex w-full md:justify-between justify-end items-center space-x-1">
          <div class="md:flex-grow flex items-center space-x-4">
            <SearchBar v-if="showSearchBar" :popularSearches="popularSearches" :cityID="cityID" class="md:flex-grow" />
          </div>
          <button
            @click="openModal"
            class="border-1 text-gray-800 dark:text-white border-2 border-solid border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-2 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
          >
            <div class="flex items-center justify-center w-full relative">
              <!-- Language icon -->
              <img src="/img/icons/language_flag.svg" class="dark:filter dark:invert dark:invert-1" />
              <!-- Flag image -->
              <img
                :src="`/img/flag/${flag}.svg`"
                class="h-3 md:h-3 rounded bg-cover absolute bottom-0 right-0 left-[4px] transform translate-x-1/3 translate-y-1/3"
                :alt="`${locale} Flag`"
              />
            </div>
          </button>
        </div>
      </div>

      <AdaptableModal :isOpen="isModalOpen" @close="closeModal">
        <template #header>
          <div class="flex items-center space-x-2 w-full mb-0">
            <img src="/img/icons/language.svg" class="dark:filter dark:invert dark:invert-1" />
            <h2 class="text-lg font-semibold">{{ $t('Regional Setting') }}</h2>
          </div>
        </template>
        <RegionalSettings @settingsUpdated="closeModal"></RegionalSettings>
      </AdaptableModal>
    </nav>
  </header>
</template>

<style scoped>
/* Your styles here */
</style>

<script setup>
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import RegionalSettings from './RegionalSettings.vue';
import AdaptableModal from './Utils/AdaptableModal.vue';

const nuxtApp = useNuxtApp();
const route = useRoute();
const router = useRouter();
const path = computed(() => route.path);
const pathName = computed(() => route.name);
const isModalOpen = ref(false);
const popularSearches = ref([]);
const { locale } = useI18n();

if (process.client) {
  var userLang = navigator.language || navigator.userLanguage;
}

const flag = computed(() => {
  switch (locale.value) {
    case 'en':
      if (userLang === 'en-GB') {
        return 'gb';
      } else {
        return 'us';
      }
    case 'fr':
      return 'fr';
    case 'de':
      return 'de';
    case 'es':
      return 'es';
    case 'it':
      return 'it';
    case 'pt':
      return 'pt';
    case 'ru':
      return 'ru';
    case 'zh':
      return 'cn';
    case 'nl':
      return 'nl';
    default:
      return 'us';
  }
});

try {
  watch(locale, () => {
    flag.value = flag.value;
  });
} catch (error) {
  console.error('Error in watch(locale, () => { ... }):', error);
}

const props = defineProps({
  data: {
    type: Object,
    required: false,
  },
});

let cityID = props.data?.cityID;

nuxtApp.hook('page:finish', () => {
  updateSearchBarVisibility();
});

// Check if the path is the homepage or homepage with language prefix
const showSearchBar = ref((!pathName?.value?.includes('search') && !pathName?.value?.includes('index')) ?? false);

const openModal = () => {
  isModalOpen.value = true;
};

const closeModal = () => {
  isModalOpen.value = false;
};

const updateSearchBarVisibility = () => {
  showSearchBar.value = !pathName?.value?.includes('search') && !pathName?.value?.includes('index');
};

// Initial check on component mount
onMounted(() => {
  router.isReady().then(() => {
    updateSearchBarVisibility(path.value);
  });
});

watch(props, () => {
  if (props.data && props.data.popularSearches) {
    popularSearches.value = props.data.popularSearches;
  }
  if (props.data) {
    cityID = props.data?.cityID;
  }
});
</script>
